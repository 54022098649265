<template>
  <div class="MarBox">
    <Header></Header>
    <div class="linkBox">
      <div @click="backHome()" class="linkBoxItem">首页</div>
      <div class="linkBoxItem">联系我们</div>
    </div>
    <div class="listBox" v-if="dataList.length>0" v-for="r in dataList" :key="r.id">
      <div class="itemBox one">{{ r.shopRegionName }}</div>
      <div class="itemBox" v-for="(i,index) in r.shopList" :key="index">
        <div><span class="itemRight">{{ i.address }}{{ i.shopName }}</span></div>
        <div><span class="itemRight tels" @click="callPhone(i.tel)">{{ i.tel }}</span></div>
      </div>
    </div>
    <el-empty description="暂无内容" v-if="dataList.length<1"></el-empty>
    <div v-if="showBtn" class="backTopBox" @click="backTop">
      <i class="el-icon-upload2"></i>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import {get} from "@/utils/request";
import deImg from "@/assets/index/pc-b.jpg";

export default {
  name: "Shops",
  components: {},
  data() {
    return {
      dataList: [],
      showBtn: false
    }
  },
  watch: {
    "$route.query": {
      handler() {
        let datas = this.$route.query;
        // console.log('watch：', datas)
        if (datas.type === 'shops') {
          this.getShops()
          this.pageType = 'shopBox'
        }
      },
      deep: true,
      immediate: true
    },
  },
  mounted() {
    window.addEventListener("scroll", this.showbtn, true);
  },
  created() {
    this.getWebInfo()
  },
  methods: {
    async getWebInfo() {
      await get("/api/webPage/getWebPageInfo").then(res => {
        if (res && res.data) {
          let backImg = res.data.backgroundImageUrl || deImg;
          this.setBgImg(backImg)
        } else {
          this.setBgImg(deImg)
        }
      }).catch(() => {
        this.setBgImg(deImg)
      })
    },
    setBgImg(img) {
      this.$nextTick(() => {
        const bodyStyle = document.body.style, // 获取body节点样式
          docEl = document.documentElement,
          docBody = document.body,
          winWidth = docEl.clientWidth || docBody.clientWidth; // 获取浏览器的宽度
        if (winWidth > 850) {
          let sty = "url(" + img + ") #b4daef no-repeat";
          bodyStyle.background = sty;
          bodyStyle.backgroundAttachment = "fixed";
        } else {
          bodyStyle.background = "#ffffff";
        }
      });
    },
    getShops() {
      get("/api/shop/queryAllShop").then(res => {
        this.dataList = res.data
      })
    },
    callPhone(phoneNumber) {
      window.location.href = 'tel://' + phoneNumber
    },
    backHome() {
      this.$router.replace({
        path: "/Index",
      })
    },
    // 回到顶部
    showbtn() {
      let that = this;
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (scrollTop > 900) {
        this.showBtn = true;
      } else {
        this.showBtn = false;
      }
    },
    backTop() {
      document.documentElement.scrollTop = 0;
    }
  }
}
</script>

<style scoped>
.linkBox {
  width: 88%;
  height: 60px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px auto;
  background-color: #fafafa;
  text-indent: 20px;
  border-radius: 8px;

  .linkBoxItem {
    height: 38px;
    font-size: 16px;
    font-weight: bold;
  }

  .linkBoxItem::after {
    content: "/";
    font-size: 20px;
    margin-left: 15px;
    color: #666;
    cursor: unset;
    font-weight: normal;
  }

  .linkBoxItem:last-child:after {
    color: #fff;
  }
}

.listBox {
  width: 83%;
  margin: 16px auto;
}

.itemBox {
  line-height: 35px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.itemBox.one {
  width: 100%;
  color: #89C122;
  font-weight: bold;
  font-size: 16px;
  text-indent: 11px;
  margin: 0 auto;
}

.itemRight {
  margin-left: 10px;
  color: #000000;
  font-weight: bold;
}

.itemRight.tels {
  font-weight: normal;
  letter-spacing: 1px;
  text-decoration: underline;
}

.pageBox {
  text-align: center;
  margin: 20px 0;
}

.backTopBox {
  background-color: rgba(0, 0, 0, 0.3);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: fixed;
  right: 180px;
  bottom: 10vh;
  font-size: 2.2rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*屏幕宽度小于*/
@media only screen and (max-width: 1000px) {
  .linkBox {
    min-height: 50px;
    height: auto;
  }

  .listBox {
    width: 87%;
  }

  .backTopBox {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    right: 30px;
  }
}

/*屏幕宽度大于1000*/
@media only screen and (min-width: 1000px) {
  .MarBox {
    max-width: 580px;
    min-width: 360px;
    margin: 0 auto;
    overflow: hidden;
    background: #fff;
    position: relative;
  }

  .linkBoxItem:hover {
    cursor: pointer;
    color: #0189ec;
  }

  .itemRight.tels:hover {
    cursor: pointer;
  }
}
</style>
